import React, { Component } from 'react';
import AlgorithmBanner from '../components/AlgorithmBanner';
import AlgorithmBody from '../components/AlgorithmBody';
import Page from '../components/Page';
import { graphql } from 'gatsby';

class AlgorithmPage extends Component {
  render() {
    return (
      <div>
        <Page>
          <AlgorithmBanner header={this.props.data.contentfulPages.header} />
          <AlgorithmBody content={this.props.data.contentfulPages} />
        </Page>
      </div>
    );
  }
}

export default AlgorithmPage;

export const query = graphql`
  query AlgorithmQuery {
    contentfulPages(title: { eq: "Algorithm" }) {
      id
      header
      bodyTitle
      body {
        body
        childMarkdownRemark {
          id
          html
        }
      }
    }
  }
`;
